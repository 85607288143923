import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { ResponseBack, ResponseBackAzure, ResponseBackAzureContrato } from '@core/models/responsehttp.model';
import { ContratoObject, InputAutorization, ItemMotivoAutorizacionComision } from '@core/models/contrato-mc.model';
import { NotificationObject } from '../models/contrato.model';
@Injectable({
  providedIn: 'root',
})
export class ContratosService {
  private readonly urlBase = 'contratos/ventas';
  private readonly httpParam = new HttpParams().append('backend', 'azure');
  protected _http = inject(HttpClient);

  postUsers(body: any) {
    return this._http.post<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/usuarios`,
      body,
      {
        params: this.httpParam,
      }
    );
  }

  postAutomoviles(body: any) {
    return this._http.post<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/automovil`,
      body,
      {
        params: this.httpParam,
      }
    );
  }

  getValidateAutomovil(vin: string, id_contrato: string) {
    return this._http.get<ResponseBackAzure>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/automovil/vin/${vin}?id_contrato=${id_contrato}`,
      {
        params: this.httpParam,
      }
    );
  }

  postVentas(body: any) {
    return this._http.post<ResponseBackAzureContrato>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}`, body, {
      params: this.httpParam,
    });
  }

  putVentas(body: any) {
    return this._http.put<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}`, body, {
      params: this.httpParam,
    });
  }

  putVentasStatus(body: Partial<ContratoObject>) {
    return this._http.put<ResponseBackAzureContrato>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/estado`, body, {
      params: this.httpParam,
    });
  }

  getVentas(filtro: string, tipo_operacion = '', responsable?: string) {
    return this._http.get<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/${
        this.urlBase
      }?filtrar=${filtro}&tipo_operacion=${tipo_operacion}&responsable=${responsable ?? ''}`,
      {
        params: this.httpParam,
      }
    );
  }

  getDetalleVentas(idVenta: string) {
    return this._http.get<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/${this.urlBase}/detalle/${idVenta}`,
      {
        params: this.httpParam,
      }
    );
  }

  postComplemento(body) {
    return this._http.post(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/cerradas/actualizar`, body, {
      params: this.httpParam,
    });
  }

  getShowRooms(state) {
    return this._http.get<ResponseBack>(
      `${environment.API_URL_MANAGEMENT}/showroom/getShowRoomsByState?state=${state}`
    );
  }

  generatePDF(ventaId: string) {
    return this._http.get<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/${this.urlBase}/pdf/${ventaId}`, {
      params: this.httpParam,
    });
  }

  generatePDFv2(ventaId: string) {
    return this._http.get<ResponseBackAzure>(`${environment.API_URL_MANAGEMENT}/v2/${this.urlBase}/pdf/${ventaId}`, {
      params: this.httpParam,
    });
  }

  //V2
  postVentasV2(body: any) {
    return this._http.post<ResponseBackAzureContrato>(`${environment.API_URL_MANAGEMENT}/v2/${this.urlBase}`, body, {
      params: this.httpParam,
    });
  }

  postVentasUploadFiles(body: any) {
    return this._http.post<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/contratos/ventas/archivos`,
      body,
      {
        params: this.httpParam,
      }
    );
  }

  getVentasFiles(idVenta: string) {
    return this._http.get<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/contratos/ventas/archivos/${idVenta}`,
      {
        params: this.httpParam,
      }
    );
  }

  //Planes de comision
  getPlanesComision() {
    return this._http.get<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/contratos/ventas/plan_comision`,
      {
        params: this.httpParam,
      }
    );
  }

  postPlanesComision(body: any) {
    return this._http.post<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/contratos/ventas/plan_comision`,
      body,
      {
        params: this.httpParam,
      }
    );
  }

  putPlanesComision(body: any) {
    return this._http.put<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/contratos/ventas/plan_comision`,
      body,
      {
        params: this.httpParam,
      }
    );
  }

  //ASOCIADOS -- vendedores
  getAsociate() {
    return this._http.get<ResponseBackAzureContrato>(`${environment.API_URL_MANAGEMENT}/contratos/ventas/asociados`, {
      params: this.httpParam,
    });
  }

  postAsociate(body: any) {
    return this._http.post<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/contratos/ventas/asociados`,
      body,
      {
        params: this.httpParam,
      }
    );
  }

  getMotivoAutorizacion() {
    return this._http.get<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/contratos/ventas/motivos/autorizacion`,
      {
        params: this.httpParam,
      }
    );
  }

  postMotivosAutorizacion(body: ItemMotivoAutorizacionComision) {
    return this._http.post<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/contratos/ventas/motivos/autorizacion`,
      body,
      {
        params: this.httpParam,
      }
    );
  }

  //Autorizacion de comision -- es un caso especial para resolver de forma manual la autorización de comisiones
  postAutorizacionComision(body: { id_contrato: string; validacion: string }) {
    return this._http.post<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/validacion/porcentaje/validar`,
      body,
      {
        params: this.httpParam,
      }
    );
  }

  getCheckStatusVenta(id_contrato: string) {
    return this._http.get<ResponseBackAzureContrato>(
      `${environment.API_URL_MANAGEMENT}/ventas/estado?id=${id_contrato}`,
      {
        params: this.httpParam,
      }
    );
  }

  putVentasV2Dinamico(body: Partial<ContratoObject>) {
    return this._http.put<ResponseBackAzureContrato>(`${environment.API_URL_MANAGEMENT}/v2/${this.urlBase}`, body, {
      params: this.httpParam,
    });
  }
}
